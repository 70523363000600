import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";
import ProductRanges from "../../packages/product-ranges";
import FormSubmissionsView from "../../packages/peracto-common/Forms/FormSubmissions/FormSubmissionsView";

import { PanelCoEditorTheme, GlobalStyles } from "../../theme/panelco";
import * as buttonVariants from "./buttonVariants";
import blocks from "../../blocks";
import { menu } from "../../menu";

import { Button } from "./Inputs/Button";
import { UserAdd, UserEdit, UserForm, UserList } from "../../packages/User";

export const PanelCoConfig = {
  api: process.env.REACT_APP_API,
  algolia: {
    applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
  },
  modules: [
    ProductRanges(),
    PeractoUser({ UserAdd, UserEdit, UserForm, UserList }),
    PeractoCommon({ FormSubmissionsView }),
    PeractoCatalogue(),
    PeractoLocations(),
    PeractoPromotions(),
    PeractoContent(),
  ],
  editorConfig: {
    theme: PanelCoEditorTheme,
    colorOptions: [
      {
        label: "Primary",
        value: PanelCoEditorTheme.colors.brand.primary,
      },
      {
        label: "Secondary",
        value: PanelCoEditorTheme.colors.brand.secondary,
      },
      { label: "White", value: PanelCoEditorTheme.colors.brand.white },
      { label: "Grey", value: PanelCoEditorTheme.colors.brand.grey },
      {
        label: "Light Grey",
        value: PanelCoEditorTheme.colors.brand.lightGrey,
      },
      {
        label: "Lighter Grey",
        value: PanelCoEditorTheme.colors.brand.lighterGrey,
      },
      {
        label: "Dark Grey",
        value: PanelCoEditorTheme.colors.brand.darkGrey,
      },
      { label: "Black", value: PanelCoEditorTheme.colors.brand.black },
      {
        label: "Success",
        value: PanelCoEditorTheme.colors.brand.success,
      },
      {
        label: "Warning",
        value: PanelCoEditorTheme.colors.brand.warning,
      },
      { label: "Danger", value: PanelCoEditorTheme.colors.brand.danger },
      { label: "Info", value: PanelCoEditorTheme.colors.brand.info },
    ],
    headings: [
      {
        model: "heading1Large",
        title: "Heading 1 Large",
        class: "heading1-large-list",
        view: {
          name: "h1",
          classes: "heading1-large",
        },
        converterPriority: "high",
      },
      {
        model: "smallHeading6",
        title: "Small",
        class: "ck-heading_heading6",
        view: {
          name: "h6",
          classes: "heading6-small",
        },
        converterPriority: "high",
      },
      {
        model: "tinyHeading",
        title: "Tiny",
        class: "ck-heading_heading6",
        view: {
          name: "h6",
          classes: "heading6-tiny",
        },
        converterPriority: "high",
      },
    ],
    locales: ["en_GB", "es_ES", "fr_FR"],
    blocks,
    buttonVariants,
    inputTypes: {
      Button,
    },
    stylesheets: [
      "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap",
    ],
    globalStyles: GlobalStyles,
  },
  features: {
    shippingServices: {
      deliveryDays: true,
      conditions: true,
    },
    products: {
      readOnly: true,
      exportProductData: true,
      productVariants: true,
      customImportTypes: [{ label: "CSV Product", value: "csv_product" }],
    },
    content: {
      indexContent: true,
    },
    forms: {
      setBearerToken: true,
    },
    user: {
      defaultBranch: true,
    },
  },
  menu,
};
