import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";
import ProductRanges from "../../packages/product-ranges";
import FormSubmissionsView from "../../packages/peracto-common/Forms/FormSubmissions/FormSubmissionsView";

import { TayFirthEditorTheme, GlobalStyles } from "../../theme/tayfirth";
import * as buttonVariants from "./buttonVariants";
import blocks from "../../blocks";
import { menu } from "../../menu";

export const TayFirthConfig = {
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        ProductRanges(),
        PeractoUser(),
        PeractoCommon({ FormSubmissionsView }),
        PeractoCatalogue(),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
    ],
    editorConfig: {
        theme: TayFirthEditorTheme,
        colorOptions: [
            {
                label: "Primary",
                value: TayFirthEditorTheme.colors.brand.primary,
            },
            {
                label: "Secondary",
                value: TayFirthEditorTheme.colors.brand.secondary,
            },
            { label: "White", value: TayFirthEditorTheme.colors.brand.white },
            { label: "Grey", value: TayFirthEditorTheme.colors.brand.grey },
            {
                label: "Light Grey",
                value: TayFirthEditorTheme.colors.brand.lightGrey,
            },
            {
                label: "Lighter Grey",
                value: TayFirthEditorTheme.colors.brand.lighterGrey,
            },
            {
                label: "Dark Grey",
                value: TayFirthEditorTheme.colors.brand.darkGrey,
            },
            { label: "Black", value: TayFirthEditorTheme.colors.brand.black },
            {
                label: "Success",
                value: TayFirthEditorTheme.colors.brand.success,
            },
            { label: "Danger", value: TayFirthEditorTheme.colors.brand.danger },
        ],
        headings: [
            {
                model: "heading1Large",
                title: "Heading 1 Large",
                class: "heading1-large-list",
                view: {
                    name: "h1",
                    classes: "heading1-large",
                },
                converterPriority: "high",
            },
            {
                model: "smallHeading6",
                title: "Small",
                class: "ck-heading_heading6",
                view: {
                    name: "h6",
                    classes: "heading6-small",
                },
                converterPriority: "high",
            },
            {
                model: "tinyHeading",
                title: "Tiny",
                class: "ck-heading_heading6",
                view: {
                    name: "h6",
                    classes: "heading6-tiny",
                },
                converterPriority: "high",
            },
        ],
        locales: ["en_GB", "es_ES", "fr_FR"],
        blocks,
        buttonVariants,
        inputTypes: {},
        stylesheets: [
            "https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Raleway:wght@400;500;600;700&display=swap",
        ],
        globalStyles: GlobalStyles,
    },
    features: {
        shippingServices: {
            deliveryDays: true,
            conditions: true,
        },
        products: {
            readOnly: true,
            exportProductData: true,
            productVariants: true,
            customImportTypes: [{ label: "CSV Product", value: "csv_product" }],
        },
        content: {
            indexContent: true,
        },
    },
    menu,
};
