import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import { HydraTable, Heading, TextFilter } from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";

const ProductRangeList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({ name: true });

    const { client } = useClient();

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                sortType: "basic",
                Filter: (props) => <TextFilter label="Name" {...props} />,
                Cell: (row) => (
                    <Link
                        to={row.row.original.id}
                        data-testid={`row-${row.row.index}-name`}
                    >
                        {row.cell.value}
                    </Link>
                ),
                selectable: false,
            },
        ],
        []
    );

    const onFetchData = (options) =>
        client(GET_LIST, "product-ranges", options);

    return (
        <>
            <Helmet>
                <title>Product Ranges | Peracto</title>
            </Helmet>
            <Heading name="Product Ranges" />

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductRangeList;
