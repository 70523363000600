import React from "react";
import { Route, Switch } from "react-router-dom";
import ProductRangesEdit from "./ProductRanges/ProductRangesEdit";
import ProductRangesForm from "./ProductRanges/ProductRangesForm";
import ProductRangesList from "./ProductRanges/ProductRangesList";

export default () => {
    return {
        routes: [
            {
                path: "/product-ranges",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={ProductRangesList}
                        />
                        <Route path={`${match.url}/:productRange`} exact>
                            {(props) => (
                                <ProductRangesEdit
                                    {...props}
                                    ProductRangesForm={ProductRangesForm}
                                />
                            )}
                        </Route>
                    </Switch>
                ),
            },
        ],
    };
};
