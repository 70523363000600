export const primary = {
    accentColor: "#737373",
    textColor: "rgba(255,255,255,1)",
    sharedProps: {
        borderRadius: "0px",
        color: "brand.white",
        textTransform: "uppercase",
        fontWeight: 300,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: {
        color: "white",
        _active: {
            boxShadow: "0 0 0px 4px rgba(90, 90, 90, 0.3) !important",
        },
    },
    outlineProps: {
        backgroundColor: "brand.white",
    },
};

export const secondary = {
    accentColor: "#222222",
    textColor: "rgba(255,255,255,1)",
    sharedProps: {
        borderRadius: "0px",
        textTransform: "uppercase",
        fontWeight: 300,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: {
        color: "white",
        _active: {
            boxShadow: "0 0 0px 4px rgba(34, 34, 34, 0.3) !important",
        },
    },
};

export const tertiary = {
    accentColor: "#FFFFFF",
    textColor: "rgba(92, 92, 96, 1)",
    sharedProps: {
        border: "1px solid #5C5C60",
        borderRadius: "0px",
        color: "brand.white",
        textTransform: "uppercase",
        fontWeight: 300,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: {
        color: "#5C5C60",
        _hover: {
            backgroundColor: "#5C5C60",
            color: "#ffffff",
        },
        _active: {
            boxShadow: "0 0 0px 4px rgba(92, 92, 96, 0.3) !important",
            backgroundColor: "#5C5C60",
        },
    },
};

export const blue = {
    accentColor: "#32688A",
    textColor: "rgba(50, 104, 138, 1)",
    sharedProps: {
        border: "1px solid #32688A",
        borderRadius: "0px",
        color: "brand.white",
        textTransform: "uppercase",
        fontWeight: 300,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: {
        color: "white",
        _active: {
            boxShadow: "0 0 0px 4px rgba(50, 104, 138, 0.3) !important",
            backgroundColor: "#32688A",
        },
    },
};

export const success = {
    accentColor: "rgba(45,178,0,1)",
    textColor: "rgba(45, 178, 0,1)",
    sharedProps: {
        borderRadius: "4px",
        textTransform: "uppercase",
        fontWeight: 300,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: { color: "brand.white" },
};
