const { lighten, darken } = require('polished')

const primaryColor = '#7B992E'
const secondaryColor = '#05508A'

export const primary = {
    accentColor: primaryColor,
    textColor: 'rgba(255,255,255,1)',
    sharedProps: {
        borderRadius: '0px',
        color: 'brand.white',
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: '16px',
        px: '30px',
        py: '10px',
    },
    solidProps: {
        color: 'white',
        _active: {
            boxShadow: '0 0 0px 4px rgba(90, 90, 90, 0.3) !important',
            backgroundColor: darken(0.1, primaryColor),
        },
        _hover: {
            backgroundColor: lighten(0.1, primaryColor),
            color: 'rgba(255,255,255,1)',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const secondary = {
    accentColor: secondaryColor,
    textColor: 'rgba(255,255,255,1)',
    sharedProps: {
        borderRadius: '0px',
        color: 'brand.white',
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: '16px',
        px: '30px',
        py: '10px',
    },
    solidProps: {
        color: 'white',
        _active: {
            boxShadow: '0 0 0px 4px rgba(90, 90, 90, 0.3) !important',
            backgroundColor: darken(0.1, secondaryColor),
        },
        _hover: {
            backgroundColor: lighten(0.1, secondaryColor),
            color: 'rgba(255,255,255,1)',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const tertiary = {
    accentColor: secondaryColor,
    textColor: 'rgba(255,255,255,1)',
    sharedProps: {
        borderRadius: '0px',
        color: 'brand.white',
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: '16px',
        px: '30px',
        py: '10px',
    },
    solidProps: {
        color: 'white',
        _active: {
            boxShadow: '0 0 0px 4px rgba(90, 90, 90, 0.3) !important',
            backgroundColor: darken(0.1, secondaryColor),
        },
        _hover: {
            backgroundColor: lighten(0.1, secondaryColor),
            color: 'rgba(255,255,255,1)',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const success = {
    accentColor: 'rgba(45,178,0,1)',
    textColor: 'rgba(45, 178, 0,1)',
    sharedProps: {
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 300,
        fontSize: '16px',
        px: '30px',
        py: '10px',
    },
    solidProps: { color: 'brand.white' },
}
