export const primary = {
    accentColor: "#005695",
    textColor: "rgba(255,255,255,1)",
    sharedProps: {
        borderRadius: "0px",
        color: "brand.white",
        textTransform: "uppercase",
        fontFamily: "heading",
        fontWeight: 600,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: {
        color: "white",
        _active: {
            boxShadow: "0 0 0px 4px rgba(0, 86, 149, 0.3) !important",
        },
    },
    outlineProps: {
        backgroundColor: "brand.white",
    },
};

export const secondary = {
    accentColor: "#222222",
    textColor: "rgba(255,255,255,1)",
    sharedProps: {
        borderRadius: "0px",
        textTransform: "uppercase",
        fontFamily: "heading",
        fontWeight: 600,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: {
        color: "white",
        _active: {
            boxShadow: "0 0 0px 4px rgba(34, 34, 34, 0.3) !important",
        },
    },
};

export const tertiary = {
    accentColor: "#686967",
    textColor: "rgba(255, 255, 255, 1)",
    sharedProps: {
        borderRadius: "0px",
        textTransform: "uppercase",
        fontFamily: "heading",
        fontWeight: 600,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: {
        color: "#FFFFFF",
        _active: {
            boxShadow: "0 0 0px 4px rgba(104, 105, 103, 0.3) !important",
        },
    },
};

export const success = {
    accentColor: "rgba(45,178,0,1)",
    textColor: "rgba(45, 178, 0,1)",
    sharedProps: {
        borderRadius: "4px",
        textTransform: "uppercase",
        fontFamily: "heading",
        fontWeight: 600,
        fontSize: "16px",
        px: "30px",
        py: "10px",
    },
    solidProps: { color: "brand.white" },
};
