import startCase from "lodash/startCase";

const setProductResources = (resources) => {
    let productResources = [];

    if (resources) {
        for (let [type, values] of Object.entries(resources)) {
            values.forEach((value, idx) => {
                const { title, description, location } = value;
                const resourceData = {
                    location,
                    title,
                    description,
                    displayOrder: idx + 1,
                };

                if (value["@id"]) {
                    // Use resource IRI for existing resources
                    resourceData["@id"] = value["@id"];
                } else {
                    // Set the type for new resources
                    resourceData.type = type;
                }

                productResources.push(resourceData);
            });
        }
    }

    return productResources;
};

const getProductResources = (resources) => {
    let productResources = {};

    resources.forEach((resource) => {
        const { type, title, description, location } = resource;
        if (!productResources.hasOwnProperty(type)) {
            productResources[type] = [];
        }
        productResources[type].push({
            "@id": resource["@id"] || null,
            type,
            title,
            location,
            description,
        });
    });

    return productResources;
};

export const formatDataForForm = (data, displayFields = []) => {
    const attrValues = {};

    if (data.resources) {
        data.productResources = getProductResources(data.resources);
    }

    return {
        ...data,
        ...attrValues,
    };
};

export const formatDataForAPI = ({ data }) => {
    const dataClone = { ...data };

    if (dataClone.productResources) {
        dataClone.resources = setProductResources(dataClone.productResources);
    }

    // productResources are only used in the UI and don't need to be passed to the API
    delete dataClone.productResources;

    for (const key of Object.keys(dataClone)) {
        if (key.startsWith("resources_")) {
            delete dataClone[key];
        }
    }

    return dataClone;
};

export const convertObjectToOptions = (obj, useKeyAsValue = false) => {
    const options = [];

    if (!!obj) {
        for (let [key, val] of Object.entries(obj)) {
            options.push({
                label: startCase(val.replace(/_/g, " ")),
                value: useKeyAsValue ? key : val,
            });
        }
    }

    return options;
};
