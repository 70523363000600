import React from "react";
import { ThemeProvider } from "styled-components";

import Config from "@peracto/peracto-config";
import Peracto from "@peracto/peracto";

// PanelCo
import { PanelCoAdminTheme } from "./theme/panelco";
import { PanelCoConfig } from "./config/panelco";

// MeyerTimber
import { MeyerTimberAdminTheme } from "./theme/meyertimber";
import { MeyerTimberConfig } from "./config/meyertimber";

// TayFirth
import { TayFirthAdminTheme } from "./theme/tayfirth";
import { TayFirthConfig } from "./config/tayfirth";

const site = process.env.REACT_APP_SITE || 'PC'

const App = () => (
    <>
        {site === 'PC' && (
            <ThemeProvider theme={PanelCoAdminTheme}>
                <Peracto config={new Config(PanelCoConfig)} />
            </ThemeProvider>
        )}

        {site === 'MT' && (
            <ThemeProvider theme={MeyerTimberAdminTheme}>
                <Peracto config={new Config(MeyerTimberConfig)} />
            </ThemeProvider>
        )}

{       site === 'TF' && (
            <ThemeProvider theme={TayFirthAdminTheme}>
                <Peracto config={new Config(TayFirthConfig)} />
            </ThemeProvider>
        )}
    </>
);

export default App;
