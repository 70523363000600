import React from "react";
import { Button as ChakraButton } from "@chakra-ui/core";

const btnStyle = {
    bg: "#737373",
    color: "white",
    borderRadius: "0px",
    textTransform: "uppercase",
    fontWeight: 300,
    fontSize: "16px",
    px: "30px",
    py: "10px",
    _active: {
        boxShadow: "0 0 0px 4px rgba(90, 90, 90, 0.3) !important",
    },
    _hover: {
        bg: "#737373",
    },
};

export const Button = (props) => {
    return (
        <ChakraButton
            variantStyle="solid"
            variantColor="blue"
            border="none"
            size="md"
            type="submit"
            display="block"
            borderRadius={0}
            {...btnStyle}
        >
            {props.children}
        </ChakraButton>
    );
};
