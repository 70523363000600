import { menuItems } from "@peracto/peracto";
const ROLE_ADMIN = "ROLE_ADMIN";

export const menu = {
    ...menuItems,
    products: {
        ...menuItems.products,
        children: {
            ...menuItems.products.children,
            productRanges: {
                label: "Product Ranges",
                path: "/product-ranges",
                sortOrder: 25,
                roles: [ROLE_ADMIN],
            },
        },
    },
};
