import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import classNames from "classnames";
import { Helmet } from "react-helmet";

import {
  HydraTable,
  Heading,
  TextFilter,
  DateRangeFilter,
  SelectFilter,
  StyledCheckbox,
  TimeAgo,
} from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

import { roleValues } from "@peracto/peracto-user";

const UserList = () => {
  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({
    roles: true,
    createdAt: true,
    lastLoggedInAt: true,
    telephone: false,
    hasCreditTerms: false,
  });

  const uppercaseFirstLetter = (value) => {
    if (value == null) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const { client } = useClient();

  const config = useConfig();
  const formatDate = config.get("user_format_date", config.get("format_date"));
  const { user } = config.get("features", {});

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  const columns = [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }) => (
        <StyledCheckbox {...row.getToggleRowSelectedProps()} />
      ),
      selectable: false,
    },
    {
      id: "avatar",
      Cell: ({ row }) => {
        return (
          //eslint-disable-next-line
          <img
            src={`https://eu.ui-avatars.com/api/?name=${row.values.firstName}+${row.values.lastName}&background=ededed&&color=666&rounded=true`}
          ></img>
        );
      },
      selectable: false,
    },
    {
      Header: "First Name",
      accessor: "firstName",
      sortType: "basic",
      Filter: (props) => <TextFilter label="First Name" {...props} />,
      Cell: (row) => <Link to={row.row.original.id}>{row.cell.value}</Link>,
      selectable: false,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Last Name" {...props} />,
      Cell: (row) => <Link to={row.row.original.id}>{row.cell.value} </Link>,
      selectable: false,
    },
    {
      Header: "Email",
      accessor: "email",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Email" type="email" {...props} />,
      selectable: false,
    },
    {
      Header: "Account",
      accessor: "type",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Account"
          options={[
            { value: "business", label: "Business" },
            { value: "private", label: "Private" },
          ]}
          isMulti={true}
          {...props}
        />
      ),
      Cell: (row) => (
        <span
          className={classNames("badge", "badge-pill", "mr-1", {
            "badge-info": row.cell.value === "private",
            "badge-success": row.cell.value === "business",
          })}
        >
          {uppercaseFirstLetter(row.cell.value)}
        </span>
      ),
      selectable: true,
    },
    {
      Header: "Roles",
      accessor: "roles",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Roles"
          options={[
            { value: "ROLE_ADMIN", label: "Admin" },
            { value: "ROLE_USER", label: "User" },
          ]}
          isMulti={true}
          {...props}
        />
      ),
      selectable: true,
      Cell: (row) =>
        row.cell.value.map((val) => (
          <span
            key={`role-${row.row.original.id}-${val}`}
            className={classNames("badge", "badge-pill", "mr-1", {
              "badge-info": val === "ROLE_ADMIN",
              "badge-success": val === "ROLE_USER",
            })}
          >
            {roleValues[val]}
          </span>
        )),
      show: selectedColumns["roles"],
    },
    {
      Header: "Created",
      accessor: "createdAt",
      sortType: "basic",
      Cell: ({ cell: { value } }) => {
        const date = new Date(value);
        const validDate = !!date.getDate() === true;

        return value && validDate ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        );
      },
      Filter: (props) => <DateRangeFilter label="Created" {...props} />,
      selectable: true,
      show: selectedColumns["createdAt"],
    },
    {
      Header: "Last login",
      accessor: "lastLoggedInAt",
      sortType: "basic",
      Cell: ({ cell: { value } }) => {
        const date = new Date(value);
        const validDate = !!date.getDate() === true;

        return value && validDate ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        );
      },
      Filter: (props) => <DateRangeFilter label="Last login" {...props} />,
      selectable: true,
      show: selectedColumns["lastLoggedInAt"],
    },
    {
      Header: "Telephone",
      accessor: "telephone",
      sortType: "basic",
      Cell: ({ cell: { value } }) => (value ? value : "-"),
      Filter: (props) => <TextFilter label="Telephone" type="tel" {...props} />,
      selectable: true,
      show: selectedColumns["telephone"],
    },
    {
      Header: "Has Credit Terms",
      accessor: "hasCreditTerms",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Has Credit Terms"
          options={[
            { value: "1", label: "Yes" },
            { value: "0", label: "No" },
          ]}
          {...props}
        />
      ),
      Cell: ({ cell: { value } }) => (
        <FontAwesomeIcon
          icon={!!value ? faCheck : faTimes}
          className={!!value ? "text-success" : "text-danger"}
        />
      ),
      selectable: true,
      show: selectedColumns["hasCreditTerms"],
    },
  ];

  const onFetchData = (options) => client(GET_LIST, "users", options);

  return (
    <>
      <Helmet>
        <title>Users | Peracto</title>
      </Helmet>
      <Heading name="Users">
        <div className="d-flex align-items-center ml-2">
          {user?.exportUserData === true && (
            <Link className="btn btn-outline-primary mr-2" to="/users/export">
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Export User Data
            </Link>
          )}

          <Link
            className="btn btn-primary"
            to="/users/add"
            data-testid="add-user"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Add User
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
                defaultFilters={{
                  firstName: "",
                  lastName: "",
                  email: "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
