import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";

import {
    GET_ONE,
    UPDATE,
    useClient,
    getSchemaFromResource,
} from "@peracto/client";

import { formatDataForForm, formatDataForAPI } from "../util";

import { MODE_EDIT } from "../ProductRangesForm";

const ProductRangesEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit Product Range">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link
                        className="btn btn-outline-primary"
                        data-testid="back-to-product-ranges"
                        to="/product-ranges"
                    >
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to Product Ranges
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    );
};

const ProductRangesEdit = ({ ProductRangesForm, match: { params } }) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();
    const [productRangeData, setProductRangeData] = useState();

    const fetchProductRange = async () => {
        try {
            const { data, response } = await client(GET_ONE, "product-ranges", {
                id: `/product-ranges/${params.productRange}`,
            });

            if (response.status === 404) {
                setRedirect("/product-ranges");
                setLoading(false);
                return;
            }

            const formatted = formatDataForForm(data);

            setProductRangeData(formatted);
            setLoading(false);
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setRedirect("/redirects");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProductRange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schema = getSchemaFromResource(getResource("product-ranges"));

    const onSubmit = async (data, actions) => {
        const formattedData = formatDataForAPI({ data });

        try {
            const response = await client(UPDATE, "product-ranges", {
                id: `/product-ranges/${params.productRange}`,
                data: formattedData,
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            } else {
                actions.setSubmitting(false);
                toast.success("Product Range successfully updated!");
            }
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                e.error.body.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            }
            actions.setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <ProductRangesEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </ProductRangesEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/product-range"
                message="The product-range you're looking for could not be found"
                buttonLabel="Go to Product Ranges"
            />
        );
    }

    return (
        <ProductRangesEditContainer>
            {redirect ? (
                <Redirect to={redirect} />
            ) : (
                <>
                    <Helmet>
                        <title>
                            {productRangeData?.name || "Product Range"} | Edit |
                            Peracto
                        </title>
                    </Helmet>
                    <ProductRangesForm
                        values={productRangeData}
                        onSubmit={onSubmit}
                        schema={schema}
                        mode={MODE_EDIT}
                    />
                </>
            )}
        </ProductRangesEditContainer>
    );
};

export default ProductRangesEdit;
