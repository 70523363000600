import React from "react";
import PropTypes from "prop-types";

import { Form, Group, Input, RichText } from "@peracto/peracto-ui";
import { useSettings } from "@peracto/peracto-hooks";
import ProductListRenderer from "./ProductListRenderer";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const ProductRangesForm = ({ mode = MODE_EDIT, values, ...props }) => {
    const { groups } = useSettings();

    const resource_types =
        groups?.product_range_resource?.values?.types?.input?.options;

    return (
        <>
            <Form
                autoComplete="off"
                values={{
                    name: "",
                    description: "",
                    productFeatures: "",
                    applications: "",
                    quality: "",
                    ...values,
                }}
                {...props}
            >
                <Group key="name" id="name" name="Name">
                    <Input name="name" label="Name" required disabled={true} />
                </Group>

                <Group key="details" id="details" name="Details">
                    <RichText name="description" label="Description" />
                </Group>

                <Group
                    key="product-range-resources"
                    id="product-range-resources"
                    name="Product Range Resources"
                >
                    <ProductListRenderer groups={resource_types} />
                </Group>
            </Form>
        </>
    );
};

ProductRangesForm.displayName = "ProductRangesForm";

ProductRangesForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ProductRangesForm;
