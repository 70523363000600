import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
  sm: ["100%", "576px"],
  md: ["100%", "768px"],
  lg: ["100%", "992px"],
  xl: ["1210px", "1210px"],
};

export const breakpoints = Object.values(sizes).reduce((bp, size) => {
  bp.push(size[1]);
  return bp;
}, []);

export const containers = Object.values(sizes).reduce((bp, size) => {
  bp.push(size[0]);
  return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const MeyerTimberEditorTheme = {
  ...theme,
  breakpoints,
  sizes: {
    ...theme.sizes,
    containers,
    breakpoints: Object.entries(sizes).reduce((bp, size) => {
      bp[size[0]] = size[1][1];
      bp[`${size[0]}Raw`] = Number.parseInt(size[1][1].replace("px", ""), 10);
      return bp;
    }, {}),
  },
  colors: {
    ...theme.colors,
    brand: {
      primary: "#7B992E",
      secondary: "#05508A",
      success: "#2DB200",
      warning: "#F49C10",
      danger: "#E30613",
      info: "#0697E3",
      white: "#FFFFFF",
      lighterGrey: "#EEEEEE",
      lightGrey: "#DEDEDE",
      grey: "#77777A",
      darkGrey: "#6D6D6D",
      black: "#222222",
    },
  },
  fonts: {
    ...theme.fonts,
    heading: "Roboto, sans-serif",
    body: "Roboto, sans-serif",
  },
};

export const MeyerTimberAdminTheme = {
  breakpoints: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 992,
    desktop: 1024,
    desktopL: 1700,
  },
};

// Global styles designs: link to the client's style guide if there is one.
const listStyles = css`
  ol:not([data-testid="breadcrumb"] > ol),
  ul:not([class^="DayPicker"]) {
    font-size: 16px;
    line-height: 18px;
    margin: revert;
    padding: revert;
  }
  ul:not([class^="DayPicker"]):not([class^="indicators"]) {
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    li {
      line-height: 30px;
      padding-left: 20px;
      margin-left: -20px;
      position: relative;
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: ${MeyerTimberEditorTheme.colors.brand.limeGreen};
      }
    }
  }
`;

const headings = css`
  h1 {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 10px;
    font-weight: bold;

    @media (max-width: 478px) {
      font-size: 24px;
      line-height: 30px;
    }

    &.heading1-large {
      font-size: 40px;
      line-height: 44px;

      @media (max-width: 478px) {
        font-size: 34px;
        line-height: 40px;
      }
    }
  }

  h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: bold;

    @media (max-width: 478px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;

    @media (max-width: 478px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  h5 {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
  }

  h6 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;

    &.heading6-small {
      font-size: 14px;
      line-height: 18px;
    }
    &.heading6-tiny {
      font-size: 12px;
      line-height: 16px;
    }
  }

  p {
    font-weight: normal;
  }
`;

const inputs = css`
  .react-dropdown-select {
    .react-dropdown-select-dropdown {
      box-shadow: none;
    }
  }
`;

const focusVisible = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

const reactPage = css`
  .ory-row {
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    text-rendering: optimizelegibility;

    color: ${MeyerTimberEditorTheme.colors.brand.darkGrey};

    blockquote {
      font-style: normal;
      border-left: 5px solid;
      border-color: ${MeyerTimberEditorTheme.colors.brand.primary};
      padding-left: 24px;
      padding-right: 24px;
    }

    hr {
      height: 1px;
      background-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
    }

    input {
      transition: border-color 0.2s;
      border-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
      border-width: 1px !important;
      border-radius: 0px;

      &:hover {
        border-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
      }
      &:focus {
        border-color: ${MeyerTimberEditorTheme.colors.brand.primary};
      }
    }

    textarea {
      transition: border-color 0.2s;
      border-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
      border-width: 1px !important;
      border-radius: 0px;

      &:hover {
        border-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
      }
      &:focus {
        border-color: ${MeyerTimberEditorTheme.colors.brand.primary};
      }
    }

    select {
      transition: border-color 0.2s;
      border-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
      border-width: 1px !important;
      border-radius: 0px;

      &:hover {
        border-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
      }
      &:focus {
        border-color: ${MeyerTimberEditorTheme.colors.brand.lightGrey};
      }

      + div {
        svg {
          color: ${MeyerTimberEditorTheme.colors.brand.primary};
        }
      }
    }

    input[type="radio"] {
      + div {
        transition: border-color 0.2s;
        border-color: ${MeyerTimberEditorTheme.colors.brand
          .lightGrey} !important;
        border-width: 1px !important;
      }

      &:hover {
        + div {
          border-color: ${MeyerTimberEditorTheme.colors.brand
            .darkGrey} !important;
        }
      }
      &:checked {
        + div {
          background-color: ${MeyerTimberEditorTheme.colors.brand
            .primary} !important;
          border-color: ${MeyerTimberEditorTheme.colors.brand
            .lightGrey} !important;
          box-shadow: inset 0px 0px 0px 2px
            ${MeyerTimberEditorTheme.colors.brand.white} !important;

          span {
            display: none;
          }
        }
      }
    }
    input[type="checkbox"] {
      + div {
        border-color: ${MeyerTimberEditorTheme.colors.brand
          .lightGrey} !important;
        border: 1px solid;

        &:hover {
          border-color: ${MeyerTimberEditorTheme.colors.brand
            .darkGrey} !important;
        }
      }

      &:checked {
        + div {
          background-color: ${MeyerTimberEditorTheme.colors.brand.primary};
          border-color: ${MeyerTimberEditorTheme.colors.brand.primary};
        }
      }
    }

    a:not([type="button"]) {
      color: ${MeyerTimberEditorTheme.colors.brand.primary};
      text-decoration: underline;
    }

    .content__buttons {
      a {
        line-height: 25px;
        height: auto;
      }
    }

    ol {
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
      position: relative;
      padding-left: 20px;
      font-size: "14px";
      counter-reset: list-number;

      li {
        margin-bottom: 15px;
        position: relative;
        counter-increment: list-number;
        &:before {
          display: block;
          content: "";
          position: absolute;
          left: -20px;
          top: 0;
          margin-right: 5px;
          content: counter(list-number) ".";
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .table {
      width: 100%;
    }

    table {
      width: 100%;

      border: 0px !important;
      th {
        border: 0px !important;
      }

      tr:first-of-type td {
        border: 0px;
        background-color: ${MeyerTimberEditorTheme.colors.brand.primary};
        color: ${MeyerTimberEditorTheme.colors.brand.white};
      }

      tr:nth-of-type(even) {
        border: 0px;
        background-color: ${MeyerTimberEditorTheme.colors.brand.white};
      }

      tr:nth-of-type(odd) {
        border: 0px;
        background-color: ${MeyerTimberEditorTheme.colors.brand.lighterGrey};
      }

      td {
        border: 0px !important;
        padding: 0.75rem !important;
        vertical-align: top;
      }
    }
  }

  .article-content {
    .ory-row {
      .content__text {
        padding-left: 0;
        padding-right: 0;

        p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .image {
        display: table !important;
        clear: both !important;
        text-align: center !important;
        margin: 1em auto !important;

        &.image-style-side {
          float: right;
          margin-left: 1.5em !important;
          max-width: 50% !important;

          img {
            display: block !important;
            margin: 0 auto !important;
            max-width: 100% !important;
            height: auto !important;
            min-width: 50px !important;
          }
        }
      }
    }
  }
`;

const carousel = css`
  .carousel-root {
    width: 100%;
  }

  .carousel {
    ul {
      margin-bottom: 0;
      list-style: none;

      li {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }

  .carousel .slide {
    text-align: left;
    background: transparent;

    ul {
      margin-bottom: 0;
      li {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }

  .carousel.carousel-slider {
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .carousel {
    .control-dots {
      display: flex;
      justify-content: center;
      bottom: -40px;
    }
  }
`;

// Simple amends to default Algolia styles that prevent us from having to recreate
// an Algolia component within the project just to add some basic styling.
const algolia = css`
  .ais-ClearRefinements-button {
    text-decoration: underline;
  }
`;

const prouductMedia = css`
  .product-media__image,
  .product-media__thumbnail {
    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  [role="dialog"] {
    .product-media__image {
      img {
        aspect-ratio: initial;
      }
    }
  }
`;

const breadcrumb = css`
  .breadcrumb {
    ol {
      margin-left: 0;

      li:first-of-type {
        padding-left: 0;
      }
    }
  }
`;

export const GlobalStyles = css`
  body {
    color: ${MeyerTimberEditorTheme.colors.brand.darkGrey};
    font-weight: normal;
  }
  ${listStyles}
  ${headings}
    ${inputs}
    ${carousel}
    ${focusVisible}
    ${reactPage}
    ${algolia}
    ${prouductMedia}
    ${breadcrumb}
`;
